import React from "react";

import { CircularProgressIndicator } from "../components/common/CircularProgressIndicator/CircularProgressIndicator";
import { SingleWrapper } from "../components/common/SimpleWrapper/SingleWrapper";

export const HocWithPreloader = ({
  isLoading = false,
  children,
  data,
  emptyText = "",
  withContainer = false,
}) => {
  if (isLoading) return <CircularProgressIndicator />;

  if (
    (typeof data === "object" && !data) ||
    (Array.isArray(data) && !data?.length) ||
    !Object.keys(data).length
  )
    return (
      emptyText && (
        <SingleWrapper
          className={withContainer ? "main-padding-block-one container" : ""}
        >
          <p className="subscription__none-title">{emptyText}</p>
        </SingleWrapper>
      )
    );

  if (typeof children === "function") return children(data);
  return children;
};
