import React, { memo } from "react";

import CancelItem from "./CancelItem";
import { LEFT_AMOUNT_TITLE } from "./languageConstants";

import { OptionsBlock } from "../OptionsBlock/OptionsBlock";
import { formatBySymbol } from "../../../../../../utils/calendar/calendarHelper";

import { useTranslation } from "next-i18next";
import { PaymentBlockContainer } from "../Payment/PaymentBlockContainer";

const optionsPaid = [-1, 5];

const TrainingCancelSection = memo(
  ({
    eventId,
    visitSet,
    setCancelTrainingData,
    phoneConfirmationRequired,
    options,
    optionsTitle,
    guestVisitEnabled,
    familyProfileId,
  }) => {
    const [t] = useTranslation();

    return (
      <>
        <div className="training-payment__title subtitle">
          {t("your_records")}
        </div>

        <div className="training-block-swiper">
          {visitSet.map(
            ({
              user: { avatar, name } = {},
              paidStatus,
              refundable,
              id,
              paid,
              payments = [],
              cards = [],
              // liqpay = null,
              // wayforpay = null,
              visitOptions = [],
              paidByCard,
            }) => {
              const visitData = {
                paid,
                visitId: id,
                eventId,
                // liqpay,
                // wayforpay,
                payments,
                phoneConfirmationRequired,
              };

              const editable = optionsPaid.includes(paid);

              const cardInfo = (paidByCard) => {
                if (!paidByCard) return null;
                const {
                  dueDate,
                  amount,
                  template: {
                    title,
                    subtitle,
                    group: { title: groupTitle } = {},
                  },
                } = paidByCard;

                const cardTitle = `${id}${
                  groupTitle && `/${groupTitle}/${title}/${subtitle}`
                }`;

                const duration = dueDate && formatBySymbol(new Date(dueDate));
                return (
                  <div className="training-cancel-block__item-status">
                    {`${cardTitle}${duration ? ` ${duration}` : ""}${
                      amount
                        ? ` (${t(LEFT_AMOUNT_TITLE(amount))} ${amount})`
                        : ""
                    }`}
                  </div>
                );
              };
              return (
                <div className="training-visit-block" key={id}>
                  <CancelItem
                    avatar={avatar}
                    name={name}
                    paidStatus={paidStatus}
                    refundable={refundable}
                    visitId={id}
                    eventId={eventId}
                    paid={paid}
                    setCancelTrainingData={setCancelTrainingData}
                    guestVisitEnabled={guestVisitEnabled}
                    familyId={familyProfileId}
                  >
                    {cardInfo(paidByCard)}
                  </CancelItem>

                  {(editable || (!editable && !!visitOptions.length)) &&
                    !!options.length && (
                      <OptionsBlock
                        options={options}
                        visitOptions={visitOptions}
                        title={optionsTitle}
                        visitId={id}
                        editable={editable}
                      />
                    )}

                  {payments && (
                    <PaymentBlockContainer
                      className="training-block-swiper-flex"
                      cards={cards}
                      visitData={visitData}
                      familyProfileId={familyProfileId}
                      onResponse={setCancelTrainingData}
                    />
                  )}
                </div>
              );
            }
          )}
        </div>
        <style jsx global>{`
          @import "./TrainingCancelSection.scss";
        `}</style>
      </>
    );
  }
);

export default TrainingCancelSection;
