import React, { useMemo } from "react";

import SubscriptionCardsBlock from "../../components/SubscriptionCardsBlock/SubscriptionCardsBlock";

import EnrollSubscription from "../SubscriptionDetailPage/EnrollSubscription";

import { useQuery } from "@apollo/client";
import {
  CARD_TEMPLATES,
  CARD_TEMPLATES_GROUP,
} from "../../../../../graphql/queries/queriesCard";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { useMediaQuery } from "../../../../../hooks/useMediaQuery";
import SubscriptionCardMobile from "../../components/SubscriptionCardsBlock/SubscriptionCardMobile";

function SubscriptionPage({ cardOpenPriceEnabled }) {
  const router = useRouter();

  const [t, { language }] = useTranslation();
  const { asPath, query } = router;

  const { isSuccess, isPayment } = query;

  const { successPayment } = useMemo(() => {
    const successPayment = isPayment
      ? t("payment_successfully_processed")
      : t("enroll_successfully_submitted");

    return { successPayment };
  }, [language, isPayment]);

  // Все категории абонементов для блока выбора

  const { data: { cardTemplates = [] } = {} } = useQuery(CARD_TEMPLATES, {
    variables: { language },
  });

  const { data: { cardTemplateGroups = [] } = {} } = useQuery(
    CARD_TEMPLATES_GROUP,
    { variables: { language } }
  );

  const { allGroupsForReturn, allCardsForReturn } = useMemo(() => {
    if (!cardTemplateGroups?.length || !cardTemplates?.length) return {};
    // Все категории абонементов для блока выбора
    const getGroups = (cards) => {
      const allGroupsForReturn = [];

      cards.forEach((searchEl) => {
        const el = cardTemplates.find(
          ({ group: { id: groupCardId } }) => searchEl.id == groupCardId
        );

        if (el) allGroupsForReturn.push(searchEl);
      });
      return allGroupsForReturn;
    };

    const allGroupsForReturn = getGroups(cardTemplateGroups);

    //Все Группы для выбора абониментов (по которым есть карточки)
    const getCardsByGroup = (template) => {
      const cardsObj = {};
      const groupsSet = new Set();

      template.forEach((card) => {
        const {
          // price,
          group: { id, title },
          // loyaltyPrice,
          // promocodePrice,
        } = card;
        //! if (isAuthorized)
        //   card.price = calculatePrice({ loyaltyPrice, promocodePrice, price });

        if (!groupsSet.has(id)) {
          groupsSet.add(id);

          cardsObj[id] = {};
          cardsObj[id].cards = [card];
          cardsObj[id].title = title;

          return;
        }
        cardsObj[id].cards = [...cardsObj[id].cards, card];
      });
      return cardsObj;
    };

    const allCardsForReturn = getCardsByGroup(cardTemplates);

    return { allGroupsForReturn, allCardsForReturn };
  }, [cardTemplateGroups, cardTemplates]);
  const isDesktop = useMediaQuery("(min-width:500px)");

  return (
    <>
      {isSuccess !== undefined && (
        <EnrollSubscription
          data={query}
          onClose={() =>
            router.replace(
              { pathname: asPath.split("?")[0], query: {} },
              undefined,
              {
                shallow: true,
              }
            )
          }
          title={successPayment}
        />
      )}

      <div className="subscription-wrapper">
        <div className="subscription-wrapper__content">
          <div className="container">
            {!!allGroupsForReturn?.length ? (
              allGroupsForReturn.map(({ id }) => {
                const { title, cards } = allCardsForReturn[id];
                return (
                  <div className="subscription-wrapper__inner" key={id}>
                    <h3 className="subscription-wrapper__inner-title">
                      {title}
                    </h3>

                    {isDesktop ? (
                      <SubscriptionCardsBlock
                        allCards={cards}
                        isArrows
                        cardOpenPriceEnabled={cardOpenPriceEnabled}
                      />
                    ) : (
                      <div className="subscription-cards-block subscription-card-mobile-wrapper">
                        {cards.map(({ id, ...props }) => {
                          return (
                            <SubscriptionCardMobile
                              {...{
                                ...props,
                                id: +id,
                                isSubscription: false,
                                cardOpenPriceEnabled,
                              }}
                              key={id}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="subscription-wrapper__inner">
                <h3 className="subscription-wrapper__inner-title">
                  {t("no_subscriptions_available")}
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx global>{`
        @import "./SubscriptionPage.scss?4";
        @import "../../components/SubscriptionCardsBlock/SubscriptionCardsBlock.scss?5";
      `}</style>
    </>
  );
}

export default SubscriptionPage;
