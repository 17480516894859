import { useCallback, useEffect, useState } from "react";

import { useRouter } from "next/router";
import {
  GET_CLIENT_EVENT_BY_PROFILE,
  GET_EVENT,
} from "../../../../../graphql/queries/queriesEvent";
import { useAuth } from "../../../../../utils/auth";

import { useTranslation } from "next-i18next";
import { useLazyQuery } from "@apollo/client";
import { useFamilyData } from "../../../../../utils/apollo/reactivities";

// eventIdDefault Нужен если вызываеться со страници рассписания
//  так же и @showPayments в котором
export default function useTrainingRequestData({
  showPayments = true,
  eventIdDefault = null,
} = {}) {
  const router = useRouter();
  const {
    query: { familyProfileId, eventId: routeEventId },
  } = router;

  const {
    i18n: { language },
  } = useTranslation();

  const { isAuthorized, accessToken } = useAuth();

  const family = useFamilyData();

  const [familyId, setFamilyId] = useState(null);

  useEffect(() => {
    if (!family.length) return;

    setFamilyId(familyProfileId ?? family?.[0]?.id);
  }, [family, familyProfileId]);

  const eventId = eventIdDefault ?? routeEventId;

  const [getEvent, { error }] = useLazyQuery(
    isAuthorized ? GET_CLIENT_EVENT_BY_PROFILE : GET_EVENT,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [{ data, isLoading }, setData] = useState({
    data: {},
    isLoading: true,
  });

  const refetch = useCallback(() => {
    getEvent({
      // context: {
      //   headers: {
      //     isRefresh: true,
      //     "Accept-Language": language,
      //   },
      // },
      variables: {
        showPayments,
        eventId,
        ...(isAuthorized && { familyId }),
      },
      onCompleted: ({ event }) => {
        if (!isAuthorized) {
          const { price } = event;

          return setData({
            data: {
              ...event,
              priceInfo: {
                regularPrice: price,
                price,
                bonusTitle: "",
              },
            },
            isLoading: false,
          });
        }

        setData({ data: event, isLoading: false });
      },
      onError: (e) => console.log(e),
    });
  }, [
    isAuthorized,
    accessToken,
    showPayments,
    language,
    eventId,
    familyId,
    getEvent,
  ]);

  useEffect(() => {
    if (!eventId || (isAuthorized && !familyId)) return;

    refetch();
  }, [eventId, isAuthorized, familyId, refetch]);

  return { data, isLoading, error, familyId, setFamilyId };
}
