import React, { memo, useCallback } from "react";

import { OptionCard } from "./OptionCard";
import { CardButton } from "../../../../../../common/CardButton/CardButton";
import {
  DELETE_VISIT_OPTION,
  ADD_VISIT_OPTION,
} from "../../../../../../../graphql/mutations/mutationEvent";
import { useMutation } from "@apollo/client";

const OptionCardWrapper = memo(
  ({
    id,
    visitId,
    editable = false,
    removeTitle,
    addTitle,
    className = "button-block",
    ordered = true,
    ...props
  }) => {
    const [changeVisitOption, { loading }] = useMutation(
      ordered ? DELETE_VISIT_OPTION : ADD_VISIT_OPTION,
      { refetchQueries: [`ClientEventDetail`] }
    );

    const onClick = useCallback(() => {
      changeVisitOption({
        variables: { visitId, options: JSON.stringify([+id]) },
      }).then(
        () => null,
        (e) => {
          console.log(e);
        }
      );
    }, [visitId, id]);

    return (
      <OptionCard {...props}>
        {editable && (
          <div className={className}>
            {ordered ? (
              <CardButton
                disabled={loading}
                title={removeTitle}
                className={"primary-button primary-button--reverse"}
                onClick={onClick}
              />
            ) : (
              <CardButton
                disabled={loading}
                title={addTitle}
                className={"primary-button"}
                onClick={onClick}
              />
            )}
          </div>
        )}
      </OptionCard>
    );
  }
);

export { OptionCardWrapper };
